<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :hasButtonTopup="true" />
        <DisbursementInfo
          :batchName="batchName"
          :approver="parseInt(approver, 10)"
          :description="description"
          v-on:changeName="updateName($event)"
          v-on:changeDescription="updateDescription($event)"
          v-on:changeApprover="updateApprover($event)"
        />
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                <h5>{{ $t("DISBURSEMENTS.DETAILS.HEADER") }}</h5>
                <hr />
                <div v-show="isProgressBarVisible()">
                  <progress-bar
                    :getCounts="getCounts"
                    :totalActualCount="totalActualCount"
                    :totalReviewed="
                      totalValidatedDisbursementCount +
                      totalInvalidatedDisbursementCount
                    "
                  />
                </div>
                <CCard>
                  <CCardBody
                    class="float-left pointer-on-hover"
                    @click="toggleCollapse('validated')"
                  >
                    <strong>
                      <span>
                        <img src="../../assets/img/success.png" width="20" />
                        {{ $t("DISBURSEMENTS.DETAILS.VALIDATED") }}
                      </span>
                    </strong>
                    <span class="text-muted mx-3">|</span>
                    <span class="text-muted">
                      {{ $t("DISBURSEMENTS.DETAILS.NUMBER") }}:
                      <strong>{{ totalValidatedDisbursementCount }}</strong>
                    </span>
                    <span class="text-muted mx-3">|</span>
                    <span class="text-muted">
                      {{ $t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT") }}:
                      <strong>Rp {{ formatAmount(totalValidatedDisbursementAmount) }}</strong>
                    </span>
                    <span>
                      <div
                        v-show="
                          !isValidatedCollapsed &&
                          totalValidatedDisbursementCount !== 0
                        "
                        class="mr-2 float-right"
                        name="cil-chevron-bottom"
                      >
                        <down-arrow />
                      </div>
                      <div
                        v-show="
                          isValidatedCollapsed &&
                          totalValidatedDisbursementCount !== 0
                        "
                        class="mr-2 float-right"
                      >
                        <up-arrow />
                      </div>
                    </span>
                  </CCardBody>
                  <CCollapse
                    :show="
                      isValidatedCollapsed &&
                      totalValidatedDisbursementCount !== 0
                    "
                  >
                    <ValidatedDisbursementDetailsTable
                      :getCounts="getCounts"
                      :totalItems="totalValidatedDisbursementCount"
                      :dynamicBorder="dynamicBorder"
                    />
                  </CCollapse>
                </CCard>
                <CCard>
                  <CCardBody
                    class="float-left pointer-on-hover"
                    @click="toggleCollapse('with_issues')"
                  >
                    <strong>
                      <img src="../../assets/img/warning.png" width="18" />
                      <span>{{ $t("DISBURSEMENTS.DETAILS.WITH_ISSUES") }}</span>
                    </strong>
                    <span class="text-muted mx-3">|</span>
                    <span class="text-muted">
                      {{ $t("DISBURSEMENTS.DETAILS.NUMBER") }}:
                      <strong>{{ totalInvalidatedDisbursementCount }}</strong>
                    </span>
                    <span class="text-muted mx-3">|</span>
                    <span class="text-muted">
                      {{ $t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT") }} :
                      <strong>
                        Rp
                        {{ formatAmount(totalInvalidatedDisbursementAmount) }}
                      </strong>
                    </span>
                    <span>
                      <div
                        v-show="
                          !isInvalidatedCollapsed &&
                          totalInvalidatedDisbursementCount !== 0
                        "
                        class="mr-2 float-right"
                      >
                        <down-arrow />
                      </div>
                      <div
                        v-show="
                          isInvalidatedCollapsed &&
                          totalInvalidatedDisbursementCount !== 0
                        "
                        class="mr-2 float-right"
                      >
                        <up-arrow />
                      </div>
                    </span>
                  </CCardBody>
                  <CCollapse
                    :show="
                      isInvalidatedCollapsed &&
                      totalInvalidatedDisbursementCount !== 0
                    "
                  >
                    <InvalidatedDisbursementDetailsTable
                      :getCounts="getCounts"
                      :totalItems="totalInvalidatedDisbursementCount"
                      :dynamicBorder="dynamicBorder"
                    />
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="mb-5">
      <CCol class="col-12">
        <CButton
          :disabled="isProgressBarVisible() 
            || allInvalid() 
            || !hasPermissionAccess(permissions.disbursementCreate)
            || $store.getters.getIsSandboxActive
          "
          color="durianprimary"
          size="lg"
          class="float-right ml-3 px-5"
          @click="toggleValidAndInvalidModalsConditionally"
        >{{ $t("DISBURSEMENTS.CREATE_BATCH.SUBMIT_BATCH") }}</CButton>
      </CCol>
    </CRow>

    <cancel-submission
      :isModalOpen="isCancelModalOpen"
      :toggleModal="toggleCancelInvalidatedModal"
    />
    <submit-invalid-disbursement-confirmation
      :isModalOpen="isSubmitInvalidatedConfirmationModalOpen"
      :toggleModal="toggleSubmitInvalidatedModal"
      :submitBatch="submitBatch"
      :totalValidatedDisbursementCount="totalValidatedDisbursementCount"
      :totalInvalidatedDisbursementCount="totalInvalidatedDisbursementCount"
    />
    <submit-disbursement-confirmation-modal
      :isModalOpen="isSubmitValidatedConfirmationModalOpen"
      :toggleModal="toggleSubmitValidatedModal"
      :submitBatch="submitBatch"
      :totalValidatedDisbursementCount="totalValidatedDisbursementCount"
      :totalValidatedDisbursementAmount="totalValidatedDisbursementAmount"
    />
    <submission-modal
      :name="batchName"
      :description="description"
      :disbursementID="disbursementID"
      :totalTransactions="totalActualCount - totalInvalidatedDisbursementCount"
      :totalAmount="totalAmount"
      :isModalOpen="isSubmissionModalOpen"
      :invalidCount="totalInvalidatedDisbursementCount"
    />
  </div>
</template>

<script>
import BalanceCard from "./components/BalanceCard.vue";
import DisbursementInfo from "./components/DisbursementInfo.vue";
import ValidatedDisbursementDetailsTable from "./components/ValidatedDisbursementDetailsTable";
import InvalidatedDisbursementDetailsTable from "./components/InvalidatedDisbursementDetailsTable";
import DownArrow from "./components/DownArrow";
import UpArrow from "./components/UpArrow";
import CancelSubmission from "./components/modals/CancelSubmission";
import SubmitDisbursementConfirmationModal from "./components/modals/SubmitDisbursementConfirmationModal";
import SubmissionModal from "./components/modals/SubmissionModal.vue";
import {
  submitDisbursement,
  getCountAndAmount
} from "@/api/disbursement.api.js";
import SubmitInvalidDisbursementConfirmation from "./components/modals/SubmitInvalidDisbursementConfirmation.vue";
import ProgressBar from "./components/ProgressBar.vue";

export default {
  components: {
    BalanceCard,
    DisbursementInfo,
    ValidatedDisbursementDetailsTable,
    InvalidatedDisbursementDetailsTable,
    DownArrow,
    UpArrow,
    CancelSubmission,
    SubmitDisbursementConfirmationModal,
    SubmitInvalidDisbursementConfirmation,
    ProgressBar,
    SubmissionModal
  },
  name: "DisbursementBatchInfo",
  data() {
    return {
      isValidatedCollapsed: false,
      isInvalidatedCollapsed: false,
      isCancelModalOpen: false,
      isSubmitInvalidatedConfirmationModalOpen: false,
      isSubmitValidatedConfirmationModalOpen: false,
      isSubmissionModalOpen: false,
      totalInvalidatedDisbursementCount: null,
      totalValidatedDisbursementCount: null,
      totalActualCount: null,
      totalInvalidatedDisbursementAmount: null,
      totalValidatedDisbursementAmount: null,
      description: "",
      approver: "",
      batchName: "",
      updatedBatchName: "",
      updatedBatchDescription: "",
      updatedApprover: "",
      disbursementID: "",
      totalAmount: null
    };
  },
  methods: {
    dynamicBorder(owner_name, real_name) {
      if (owner_name.toLowerCase() !== real_name.toLowerCase()) {
        return { "border-color": "#FFCE00" };
      }
    },
    toggleCollapse(tableToBeCollapsed) {
      if (tableToBeCollapsed === "validated")
        this.isValidatedCollapsed = !this.isValidatedCollapsed;
      else if (tableToBeCollapsed === "with_issues")
        this.isInvalidatedCollapsed = !this.isInvalidatedCollapsed;
    },
    toggleCancelInvalidatedModal() {
      this.isCancelModalOpen = !this.isCancelModalOpen;
    },
    toggleSubmitValidatedModal() {
      this.isSubmitValidatedConfirmationModalOpen = !this
        .isSubmitValidatedConfirmationModalOpen;
    },
    toggleSubmitInvalidatedModal() {
      this.isSubmitInvalidatedConfirmationModalOpen = !this
        .isSubmitInvalidatedConfirmationModalOpen;
    },
    toggleValidAndInvalidModalsConditionally() {
      this.totalInvalidatedDisbursementCount === 0
        ? this.toggleSubmitValidatedModal()
        : this.toggleSubmitInvalidatedModal();
    },
    toggleSubmissionModal() {
      this.isSubmissionModalOpen = !this.isSubmissionModalOpen;
    },
    updateName(data) {
      this.updatedBatchName = data;
    },
    updateDescription(data) {
      this.updatedBatchDescription = data;
    },
    updateApprover(data) {
      this.updatedApprover = data;
    },
    isProgressBarVisible() {
      return (
        this.totalActualCount !==
        this.totalValidatedDisbursementCount +
          this.totalInvalidatedDisbursementCount
      );
    },
    allInvalid() {
      return this.totalValidatedDisbursementCount === 0;
    },
    async submitBatch() {
      try {
        const payload = {
          batch_name: this.updatedBatchName,
          description: this.updatedBatchDescription,
          approved_by: this.updatedApprover
        };
        const response = await submitDisbursement(
          this.$route.params.id,
          payload
        );
        this.totalAmount = response.data.data.total_amount;
        this.disbursementID = response.data.data.id;
        const err = response.data.data.data;
        if (err) {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.NO_ITEMS_TO_SUBMIT"));
          return;
        }
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.SUBMIT_SUCCESS")
        );
      } catch (error) {
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.NO_ITEMS_TO_SUBMIT"));
      }
      this.toggleValidAndInvalidModalsConditionally();
      this.toggleSubmissionModal();
    },
    async getCounts() {
      try {
        const response = (await getCountAndAmount(this.$route.params.id)).data
          .data;
        this.totalInvalidatedDisbursementCount =
          response.invalid_disbursement_count;
        this.totalValidatedDisbursementCount =
          response.valid_disbursement_count;
        this.totalActualCount = response.total_count;
        this.totalInvalidatedDisbursementAmount =
          response.invalid_disbursement_amount;
        this.totalValidatedDisbursementAmount =
          response.valid_disbursement_amount;
        this.updatedBatchDescription = this.description = response.description;
        this.updatedBatchName = this.batchName = response.name;
        this.updatedApprover = this.approver = response.approver_id;
        return (
          response.valid_disbursement_count +
          response.invalid_disbursement_count
        );
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_DETAILS")
        );
        return -1;
      }
    }
  },
  mounted() {
    this.getCounts();
  }
};
</script>
<style scoped>
.header {
  margin-left: 25%;
  margin-top: 5%;
}
.margin-top {
  margin-top: 5%;
}
td {
  padding: 5px;
}
</style>