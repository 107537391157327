<template>
  <CModal :centered="true" :show="isSubmissionModalOpen">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <img id="image" src="../../../../assets/img/success.png" width="100" />
      <h4 class="align-center">
        {{ $t("DISBURSEMENTS.SUCCESS_MODAL.HEADER") }}
      </h4>
      <div>
        <strong>
          <div class="p-3 d-flex flex-row justify-content-between greybox">
            <span>{{ $t("DISBURSEMENTS.SUCCESS_MODAL.BATCH_NAME") }}</span>
            <span>{{ name }}</span>
          </div>
          <div class="p-3 d-flex flex-row justify-content-between">
            <span>{{ $t("DISBURSEMENTS.SUCCESS_MODAL.SUBMISSION_ID") }}</span>
            <span>{{ disbursementID }}</span>
          </div>
          <div class="p-3 d-flex flex-row justify-content-between">
            <span>{{ $t("DISBURSEMENTS.SUCCESS_MODAL.TOTAL_AMOUNT") }}</span>
            <span>Rp {{ formatAmount(parseInt(totalAmount, 10)) }}</span>
          </div>
          <div class="p-3 d-flex flex-row justify-content-between">
            <span>{{
              $t("DISBURSEMENTS.SUCCESS_MODAL.TOTAL_TRANSACTION")
            }}</span>
            <span>{{ totalTransactions }}</span>
          </div>
          <div class="p-3 d-flex flex-row justify-content-between">
            <span>{{ $t("DISBURSEMENTS.SUCCESS_MODAL.DESCRIPTION") }} </span>
            <span>{{ description }}</span>
          </div>
        </strong>
      </div>
      <div>
        <CButton
          id="button"
          @click="modalButton()"
          size="lg"
          color="durianprimary"
          class="float-right"
        >
          {{ getButtonLabel() }}</CButton
        >
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
import {
  exportDisbursementBatchItems,
} from "@/api/disbursement.api.js";
const VALIDATION_STATUS = "invalid";
const XLSX = ".xlsx";
export default {
  name: "SubmissionModal",
  props: {
    name: String,
    disbursementID: String,
    totalTransactions: Number,
    description: String,
    totalAmount: String,
    isModalOpen: Boolean,
    invalidCount: Number,
  },
  data() {
    return {
      isSubmissionModalOpen: false,
    };
  },
  updated() {
    this.isSubmissionModalOpen = this.isModalOpen;
  },
  methods: {
    getButtonLabel() {
      if (this.invalidCount > 0) {
        return this.$t("DISBURSEMENTS.SUCCESS_MODAL.EXPORT_BUTTON");
      }
      return this.$t("DISBURSEMENTS.SUCCESS_MODAL.BUTTON");
    },
    modalButton() {
      if (this.invalidCount > 0) {
        this.exportDisbursementBatch();
      }
      this.goTo("/disbursements/");
    },
    async exportDisbursementBatch() {
      try {
        const params = { status: VALIDATION_STATUS, action: "export"};
        const response = await exportDisbursementBatchItems(
          this.disbursementID,
          params
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const linkElement = document.createElement("a");
        linkElement.href = url;
        var fileName = this.getCSVName("disbursement_items") + XLSX;
        linkElement.setAttribute("download", fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        linkElement.remove();
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERROR_FETCHING_DISBURSEMENTS")
        );
      }
    },
  }
};
</script>
<style scoped>
#image {
  margin-top: 10%;
  margin-left: 38%;
}
#button {
  width: 100%;
}
.greybox {
  background-color: #F1F1F1;
}
</style>