<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <div>
        <h5 class="header">{{ $t("DISBURSEMENTS.CANCEL_MODAL.HEADER") }}</h5>
      </div>
      <div>
        {{ $t("DISBURSEMENTS.CANCEL_MODAL.MESSAGE") }}
      </div>
      <div class="p-3 d-flex flex-row justify-content-between">
        <CButton @click="toggleModal">
          <strong>
            <h5>{{ $t("DISBURSEMENTS.CANCEL_MODAL.BUTTON_NO") }}</h5>
          </strong>
        </CButton>
        <CButton color="durianprimary" @click="goTo('/disbursements')">
          <strong>
            <h5>{{ $t("DISBURSEMENTS.CANCEL_MODAL.BUTTON_YES") }}</h5>
          </strong>
        </CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
  },
};
</script>
