<template>
  <div>
    <CRow>
      <CCol
        col="8"
        xl="10"
      > </CCol>
      <CCol
        col="2"
        xl="2"
      >
        <CButton
          size="lg"
          @click="toggleDeleteModal"
          :disabled="rowCheckedCount === 0"
        >
          <strong>
            {{ $t("DISBURSEMENTS.DETAILS.TABLE.DELETE") }}
          </strong>
        </CButton>
      </CCol>
      <CCol col="2"></CCol>
    </CRow>
    <div class="table-responsive">
      <table class="table horizontally-scrollable xl-width-table">
        <thead class="table-header">
          <tr>
            <td>
              <input
                type="checkbox"
                class="durianpay-checkbox"
                :checked="isHeaderChecked && totalCountOfItems !== 0"
                :disabled="totalCountOfItems === 0"
                @change="handleHeaderChecked()"
              />
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.CURRENCY") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_OWNER_NAME") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.REAL_NAME") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.AMOUNT") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.BANK_CODE") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.EMAIL") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.PHONE") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.NOTES") }}
            </td>
          </tr>
        </thead>
        <tbody v-if="validatedTransactions.length !== 0">
          <tr
            v-for="(value, key) in validatedTransactions"
            :key="key"
          >
            <td>
              <input
                :id="value.id"
                type="checkbox"
                class="durianpay-checkbox"
                @change="checkboxMarking(value.id)"
                :checked="rowIsChecked[value.id]"
              />
            </td>
            <td>IDR</td>
            <td>
              <input
                type="text"
                class="input-warning-custom"
                :value="getAccountOwnerName(value.id.toString())"
                :style="
                  dynamicBorder(value.account_owner_name, value.real_name)
                "
                @change="
                  (event) => {
                    updateItem(
                      value.id.toString(),
                      'account_owner_name',
                      event.target.value
                    );
                  }
                "
              />
              <img
                src="../../../assets/img/warning-triangle.svg"
                class="inside-input pointer-on-hover"
                v-show="
                  !(
                    value.real_name.toLowerCase() ===
                    value.account_owner_name.toLowerCase()
                  )
                "
                v-c-popover="{
                  html: true,
                  header: `<strong>${$t(
                    'DISBURSEMENTS.POP_OVER.MESSAGE_1'
                  )}</strong>`,
                  content: `${$t('DISBURSEMENTS.POP_OVER.MESSAGE_2')}`,
                  active: false,
                  placement: 'bottom-end',
                }"
              />
            </td>
            <td class="name-column">
              <CInput
                class="name-input"
                type="text"
                :value="value.real_name"
                :disabled="true"
              >
              </CInput>
            </td>
            <td>
              <CInput
                prepend="Rp."
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.AMOUNT')"
                type="number"
                :value="trimEditableAmount(value.amount)"
                @update:value="
                  (newValue) => {
                    updateItem(value.id.toString(), 'amount', newValue);
                  }
                "
                :lazy="1000"
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.BANK_CODE')"
                type="text"
                :value="value.bank_code"
                :disabled="true"
              />
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER')"
                type="text"
                aria-valuemin="0"
                :value="value.account_number"
                :disabled="true"
              >
              </CInput>
            </td>
            <td>
              <input
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.EMAIL')"
                type="text"
                class="input-warning-custom"
                v-if="showUpdate"
                :value="getTransactionEmail(value.id.toString())"
                :style="dynamicBorderEmail(value.id.toString())"
                @change="
                  (event) => {
                    updateItem(
                      value.id.toString(),
                      'email_recipient',
                      event.target.value
                    );
                  }
                "
              />
              <img
                src="../../../assets/img/warning-red.svg"
                class="inside-input pointer-on-hover"
                v-if="showUpdate"
                v-show="dynamicBorderEmailWarning(value.id.toString())"
                v-c-popover="{
                  html: true,
                  header: `<strong>${$t(
                    'DISBURSEMENTS.POP_OVER.EMAIL_WARNING_HEADER'
                  )}</strong>`,
                  content: `${$t('DISBURSEMENTS.POP_OVER.EMAIL_WARNING_CONTENT')}`,
                  active: false,
                  placement: 'bottom-end',
                }"
              />
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.PHONE')"
                type="text"
                aria-valuemin="0"
                :is-valid="validatePhone"
                :invalid-feedback="$t('VALIDATION_ERROR.PHONE')"
                :value="value.phone_number"
                @update:value="
                  (newValue) => {
                    updateItem(value.id.toString(), 'phone_number', newValue);
                  }
                "
                :lazy="1000"
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.NOTES')"
                type="text"
                aria-valuemin="0"
                :is-valid="validateNotes"
                :invalid-feedback="$t('VALIDATION_ERROR.NOTES')"
                :value="value.notes"
                @update:value="
                  (newValue) => {
                    updateItem(value.id.toString(), 'notes', newValue);
                  }
                "
                :lazy="1000"
              >
              </CInput>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <delete-disbursement-modal
      :isModalOpen="isDeleteModalOpen"
      :toggleModal="toggleDeleteModal"
      :deleteDisbursementItems="deleteDisbursementItems"
    />
  </div>
</template>

<script>
import {
  getDisbursementItemsByID,
  updateDisbursementItems,
  deleteDisbursementItems
} from "@/api/disbursement.api.js";
import DeleteDisbursementModal from "./modals/DeleteDisbursementModal.vue";
const VALIDATION_STATUS = "valid";

export default {
  components: { DeleteDisbursementModal },
  name: "ValidatedDisbursementDetailsTable",
  data() {
    return {
      isHeaderChecked: false,
      transactionsDetails: {},
      rowIsChecked: {},
      validatedTransactions: [],
      rowCheckedCount: 0,
      totalCountOfItems: 0,
      isDeleteModalOpen: false,
      showPopover: false,
      showUpdate: true
    };
  },
  props: {
    totalItems: Number,
    getCounts: Function,
    dynamicBorder: Function
  },
  methods: {
    checkForHeaderFromCount() {
      this.isHeaderChecked = this.rowCheckedCount === this.totalCountOfItems;
    },
    checkboxMarking(id) {
      this.rowIsChecked[id] = !this.rowIsChecked[id];
      this.rowIsChecked[id] ? this.rowCheckedCount++ : this.rowCheckedCount--;
      this.checkForHeaderFromCount();
    },
    handleHeaderChecked() {
      this.isHeaderChecked = !this.isHeaderChecked;
      this.rowCheckedCount = this.isHeaderChecked ? this.totalCountOfItems : 0;
      this.validatedTransactions.forEach(transaction => {
        this.rowIsChecked[transaction.id] = this.isHeaderChecked;
      });
    },
    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },
    async deleteDisbursementItems() {
      const checkedItems = [];
      for (let id in this.rowIsChecked) {
        this.rowIsChecked[id] === true ? checkedItems.push(id) : null;
      }

      if (checkedItems.length === 0) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.NO_ITEMS_SELECTED_ON_DELETE")
        );
        return;
      }
      try {
        var payload = { disbursement_batch_item_id: checkedItems };
        await deleteDisbursementItems(JSON.stringify(payload));
        await this.getCounts();
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.SUCCESS_DELETE")
        );
        this.rowCheckedCount = 0;
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.FAIL_DELETE_ITEM") + error.message
        );
      }
      this.isDeleteModalOpen = false;
    },
    initialiseMaps() {
      this.rowCheckedCount = 0;
      this.transactionsDetails = {};
      this.rowIsChecked = {};
      this.totalCountOfItems = 0;
      this.validatedTransactions.forEach(transaction => {
        this.transactionsDetails[transaction.id] = transaction;
        this.rowIsChecked[transaction.id] = false;
        this.totalCountOfItems++;
      });
    },
    async updateItem(transactionID, feild, newValue) {
      this.transactionsDetails[transactionID][feild] = newValue;
      const {
        id,
        amount,
        account_owner_name,
        email_recipient,
        phone_number,
        notes
      } = this.transactionsDetails[transactionID];
      const payload = {
        id,
        amount: amount.toString(),
        account_owner_name,
        email: email_recipient,
        phone_number,
        notes
      };

      this.showUpdate = !this.showUpdate;

      try {
        const response = await updateDisbursementItems(payload);
        if (!this.verifyHttpResponse(response)) {
          this.transactionsDetails[transactionID] = response.data.data;
        } else {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_UPDATE"));
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.FAIL_UPDATE") + error.message
        );
      }

      this.showUpdate = !this.showUpdate;
    },
    async getDisbursementItems() {
      try {
        const params = { status: VALIDATION_STATUS };
        const response = await getDisbursementItemsByID(
          this.$route.params.id,
          params
        );
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.validatedTransactions = data.disbursement_batch_items;
        } else {
          this.validatedTransactions = [];
        }
        this.initialiseMaps();
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_DISBURSEMENTS")
        );
      }
    },
    getAccountOwnerName(id) {
      return this.transactionsDetails[id].account_owner_name;
    },
    getTransactionEmail(id) {
      return this.transactionsDetails[id].email_recipient;
    },
    dynamicBorderEmailWarning(id) {
      if (!this.validateEmail(this.transactionsDetails[id].email_recipient)) {
        return true;
      }
      return false;
    },
    dynamicBorderEmail(id) {
      if (!this.validateEmail(this.transactionsDetails[id].email_recipient)) {
        return { "border-color": "#E86163" };
      }
      return { "border-color": "#d8dbe0" };
    }
  },
  watch: {
    totalItems: function() {
      this.getDisbursementItems();
    }
  },
  mounted() {
    this.getDisbursementItems();
  }
};
</script>
<style scoped>
.table-header td {
  padding-top: 14px;
  padding-bottom: 5px;
}
.inside-input {
  position: relative;
  left: 82%;
  bottom: 30px;
  padding: 2px;
}
.name-column {
  width: 14%;
  margin-left: 100px;
}
.name-input {
  width: 100%;
}
.input-warning-custom {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.18rem;
  font-size: 0.875rem;
  padding-left: 1%;
  width: 100%;
  color: #768192;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}
.input-warning-custom:focus {
  outline-width: 0;
}
</style>
