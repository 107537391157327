<template>
  <div class="progress-bar">
    <div class="info">
      <span class="check-transaction-text">{{
        $t("DISBURSEMENTS.PROGRESS_BAR.CHECKING")
      }}</span>
      <span class="padding-around text-muted"> | </span>
      <span class="text-muted">
        {{ $t("DISBURSEMENTS.PROGRESS_BAR.TOTAL_CHECKED") }}
        <strong>{{ totalReviewed }}</strong></span
      >
    </div>
    <div>
      <CProgress class="space">
        <CProgressBar
          :value="(100 * totalReviewed) / totalActualCount"
          color="success"
          :striped="true"
        />
      </CProgress>
    </div>
  </div>
</template>

<script>
import Polling from "../../../utils/Polling.js";
import { polling } from "../../../../config";

export default {
  name: "ProgressBar",
  props: {
    getCounts: Function,
    totalActualCount: Number,
    totalReviewed: Number,
  },
  data() {
    return {
      polling: null,
      totalCountAfterResolve: false,
    };
  },
  methods: {
    async pollingEntity() {
      this.totalCountAfterResolve = await this.getCounts();
      return this.totalCountAfterResolve;
    },
    async poll() {
      try {
        const response = await Polling(
          this.pollingEntity,
          polling.pollingAttempInterval,
          polling.maxAttemps
        );
        if (response === this.totalActualCount) {
          this.stopPolling();
        }
      } catch (error) {
        this.stopPolling();
      }
    },
    async startPolling() {
      //clear the previous instance of poll before creating a new one.
      if (this.polling) clearInterval(this.polling);
      this.polling = setInterval(this.poll, polling.pollingInterval);
    },
    stopPolling() {
      clearInterval(this.polling);
    },
  },
  updated() {
    if (this.totalActualCount !== this.totalReviewed) {
      this.startPolling();
    } else if (this.totalActualCount === this.totalReviewed) {
      this.stopPolling();
    }
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>
<style scoped>
.progress-bar {
  color: black;
  background-color: #fefaea;
  padding-top: 10px;
  padding-bottom: 25px;
}
.info {
  margin-right: 60%;
  margin-bottom: 1%;
  margin-top: 1%;
}
.check-transaction-text {
  font-weight: bold;
  margin-left: 6%;
  color: #4e4f52;
  font-size: 16px;
}
.padding-around {
  padding-right: 4%;
  padding-left: 4%;
}
.space {
  margin-left: 3%;
  margin-right: 3%;
}
</style>