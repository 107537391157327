let attemps = 0;
const Polling = async (pollingEntity, interval, maxAttempts) => {
    const pollingPromise = new Promise(async (resolve, reject) => {
        const result = await pollingEntity();
        attemps++;
        if (result >= 0) {
            attemps = 0;
            resolve(result);
        } else if (attemps >= maxAttempts) {
            reject(new Error('Maximum Attempts reached'));
        } else {
            setTimeout(pollingPromise, interval, resolve, reject);
        }
    });
    return pollingPromise;
};

export default Polling;