<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <div>
        <h5 class="header">
          {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.HEADER") }}
        </h5>
      </div>
      <div class="margin-around align-text-center">
        {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.MESSAGE_1") }}
        <strong class="error-text">
          {{ totalInvalidatedDisbursementCount }}
        </strong>
        {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.MESSAGE_2") }}
        <strong>
          {{
            totalInvalidatedDisbursementCount + totalValidatedDisbursementCount
          }}
        </strong>
        {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.MESSAGE_3") }}
        <br /><br />
        <strong>
          {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.MESSAGE_4") }}
        </strong>
      </div>
      <div class="p-3 d-flex flex-row justify-content-between">
        <CButton @click="toggleModal">
          <strong>
            <h5>{{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.CANCEL") }}</h5>
          </strong>
        </CButton>
        <CButton color="durianprimary" @click="submitBatch()">
          <strong>
            <h5>
              {{ $t("DISBURSEMENTS.MODAL_WITH_ISSUES.SUBMIT_ANYWAY") }}
            </h5>
          </strong>
        </CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    totalInvalidatedDisbursementCount: Number,
    totalValidatedDisbursementCount: Number,
    toggleModal: Function,
    submitBatch: Function,
  },
};
</script>
<style scoped>
.header {
  margin-left: 25%;
  margin-top: 5%;
}
</style>