<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <table class="with-padding margin-top">
        <tr>
          <td>
            <strong
              ><h5>
                {{ $t("DISBURSEMENTS.MODAL_WITHOUT_ISSUES.HEADER") }}
              </h5></strong
            >
          </td>
          <td></td>
        </tr>

        <tr>
          <td>
            {{ $t("DISBURSEMENTS.MODAL_WITHOUT_ISSUES.TOTAL_TRANSACTIONS") }}
          </td>
          <td>
            <strong>{{ totalValidatedDisbursementCount }} </strong>
          </td>
        </tr>
        <tr>
          <td>{{ $t("DISBURSEMENTS.MODAL_WITHOUT_ISSUES.TOTAL_AMOUNT") }}</td>
          <td>
            <strong>Rp {{ formatAmount(totalValidatedDisbursementAmount) }}</strong>
          </td>
        </tr>
      </table>
      <br />
      <div class="d-flex flex-row justify-content-between">
        <CButton @click="toggleModal">
          <strong>
            {{ $t("DISBURSEMENTS.MODAL_WITHOUT_ISSUES.CANCEL_BUTTON") }}</strong
          >
        </CButton>
        <br />
        <CButton color="durianprimary" @click="submitBatch">
          {{ $t("DISBURSEMENTS.MODAL_WITHOUT_ISSUES.SUBMIT_BUTTON") }}
        </CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    submitBatch: Function,
    totalValidatedDisbursementCount: Number,
    totalValidatedDisbursementAmount: String,
  },
};
</script>
<style scoped>
.header {
  margin-left: 25%;
  margin-top: 5%;
}
.margin-top {
  margin-top: 5%;
}
</style>