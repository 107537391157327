<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="rotated"
  >
    <path
      d="M1.33398 1L6.00065 5.66667L10.6673 1"
      stroke="#4E4F52"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UpArrow",
};
</script>

<style scoped>
.rotated {
    transform: rotate(-180deg);
}
</style>