<template>
  <div>
    <CRow class="p-3 d-flex flex-row justify-content-between">
      <CCol
        col="7"
        xl="9"
      >
        <span class="error-text">{{
          $t("DISBURSEMENTS.DETAILS.WITH_ISSUES_MESSAGE")
        }}</span>
      </CCol>
      <CCol
        col="3"
        xl="3"
      >
        <CButton
          @click="validateItems"
          color="durianprimary"
          size="lg"
          class="float-left ml-3"
          :disabled="rowCheckedCount === 0"
        >
          {{ $t("DISBURSEMENTS.DETAILS.TABLE.VALIDATE") }}
        </CButton>
        <CButton
          size="lg"
          class="float-left ml-3"
          :disabled="rowCheckedCount === 0"
          @click="toggleDeleteModal"
        >
          <strong>{{ $t("DISBURSEMENTS.DETAILS.TABLE.DELETE") }}</strong>
        </CButton>
      </CCol>
      <CCol col="1"></CCol>
    </CRow>
    <div class="table-responsive">
      <table class="table horizontally-scrollable xl-width-table">
        <thead class="table-header">
          <tr>
            <td>
              <input
                type="checkbox"
                class="durianpay-checkbox"
                :checked="isHeaderChecked && totalCountOfItems !== 0"
                :disabled="totalCountOfItems === 0"
                @change="handleHeaderChecked()"
              />
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.CURRENCY") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_OWNER_NAME") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.REAL_NAME") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.AMOUNT") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.BANK_CODE") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.EMAIL") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.PHONE") }}
            </td>
            <td>
              {{ $t("DISBURSEMENTS.DETAILS.TABLE.NOTES") }}
            </td>
          </tr>
        </thead>
        <tbody v-if="invalidatedTransactions.length !== 0">
          <tr
            v-for="(value, key) in invalidatedTransactions"
            :key="key"
          >
            <td>
              <input
                :id="value.id"
                type="checkbox"
                class="durianpay-checkbox"
                @change="checkboxMarking(value.id)"
                :checked="rowIsChecked[value.id]"
              />
            </td>
            <td>IDR</td>
            <td>
              <input
                type="text"
                :style="
                  dynamicBorder(value.account_owner_name, value.real_name)
                "
                class="input-warning-custom"
                :value="getAccountOwnerName(value.id.toString())"
                @change="
                  (event) => {
                    updateItem(
                      value.id.toString(),
                      'account_owner_name',
                      event.target.value
                    );
                  }
                "
              />
              <img
                src="../../../assets/img/warning-triangle.svg"
                class="inside-input"
                v-show="
                  !(
                    value.real_name.toLowerCase() ===
                    value.account_owner_name.toLowerCase()
                  )
                "
              />
            </td>
            <td class="name-column">
              <CInput
                type="text"
                class="name-input"
                :value="value.real_name"
                :disabled="true"
              >
              </CInput>
            </td>

            <td>
              <CInput
                prepend="Rp."
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.AMOUNT')"
                type="number"
                :is-valid="
                  isAmountFeildValid(value.id)
                "
                :value="trimEditableAmount(value.amount)"
                @change="
                  (newValue) => {
                    updateItem(
                      value.id.toString(),
                      'amount',
                      newValue.toString()
                    );
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CSelect
                type="text"
                :is-valid="isBankCodeAndAccountValid(value.id)"
                :value="value.bank_code"
                :options="getBankDetailsOptions()"
                @change="
                  (newValue) => {
                    updateSelectItem(
                      value.id.toString(),
                      'bank_code',
                      newValue
                    );
                  }
                "
              />
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.ACCOUNT_NUMBER')"
                type="text"
                aria-valuemin="0"
                :is-valid="isBankCodeAndAccountValid(value.id)"
                :value="value.account_number"
                @change="
                  (newValue) => {
                    updateItem(value.id.toString(), 'account_number', newValue);
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <input
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.EMAIL')"
                type="text"
                class="input-warning-custom"
                :value="getTransactionEmail(value.id.toString())"
                :style="dynamicBorderEmail(value.id.toString())"
                v-if="showUpdate"
                @change="
                  (event) => {
                    updateItem(
                      value.id.toString(),
                      'email_recipient',
                      event.target.value
                    );
                  }
                "
              />
              <img
                src="../../../assets/img/warning-red.svg"
                class="inside-input pointer-on-hover"
                v-show="dynamicBorderEmailWarning(value.id.toString())"
                v-if="showUpdate"
                v-c-popover="{
                  html: true,
                  header: `<strong>${$t(
                    'DISBURSEMENTS.POP_OVER.EMAIL_WARNING_HEADER'
                  )}</strong>`,
                  content: `${$t('DISBURSEMENTS.POP_OVER.EMAIL_WARNING_CONTENT')}`,
                  active: false,
                  placement: 'bottom-end',
                }"
              />
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.PHONE')"
                type="text"
                aria-valuemin="0"
                :is-valid="validatePhone"
                :invalid-feedback="$t('VALIDATION_ERROR.PHONE')"
                :value="value.phone_number"
                @change="
                  (newValue) => {
                    updateItem(value.id.toString(), 'phone_number', newValue);
                  }
                "
              >
              </CInput>
            </td>
            <td>
              <CInput
                :placeholder="$t('DISBURSEMENTS.DETAILS.TABLE.NOTES')"
                type="text"
                aria-valuemin="0"
                :is-valid="validateNotes"
                :invalid-feedback="$t('VALIDATION_ERROR.NOTES')"
                :value="value.notes"
                @change="
                  (newValue) => {
                    updateItem(value.id.toString(), 'notes', newValue);
                  }
                "
              >
              </CInput>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <delete-disbursement-modal
      :isModalOpen="isDeleteModalOpen"
      :toggleModal="toggleDeleteModal"
      :deleteDisbursementItems="deleteDisbursementItems"
    />
  </div>
</template> 
<script>
import {
  getDisbursementItemsByID,
  getBankList,
  deleteDisbursementItems,
  validateDisbursementItems
} from "@/api/disbursement.api.js";
import DeleteDisbursementModal from "./modals/DeleteDisbursementModal.vue";

const VALIDATION_STATUS = "invalid";
const BANK_CODE = "bank_code";
const AMOUNT = "amount";
const ACCOUNT_NUMBER = "account_number";

export default {
  name: "InvalidatedDisbursementDetailsTable",
  components: { DeleteDisbursementModal },
  data() {
    return {
      isDeleteModalOpen: false,
      isHeaderChecked: false,
      transactionsDetails: {},
      rowIsChecked: {},
      invalidatedTransactions: [],
      rowCheckedCount: 0,
      totalCountOfItems: 0,
      bankDetails: [],
      showUpdate: true
    };
  },
  props: { getCounts: Function, totalItems: Number, dynamicBorder: Function },
  methods: {
    getTransactionEmail(id) {
      return this.transactionsDetails[id].email_recipient;
    },
    dynamicBorderEmail(id) {
      if (!this.validateEmail(this.transactionsDetails[id].email_recipient)) {
        return { "border-color": "#E86163" };
      }
      return { "border-color": "#d8dbe0" };
    },
    checkForHeaderFromCount() {
      this.isHeaderChecked = this.rowCheckedCount === this.totalCountOfItems;
    },
    checkboxMarking(id) {
      this.rowIsChecked[id] = !this.rowIsChecked[id];
      this.rowIsChecked[id] ? this.rowCheckedCount++ : this.rowCheckedCount--;
      this.checkForHeaderFromCount();
    },
    handleHeaderChecked() {
      this.isHeaderChecked = !this.isHeaderChecked;
      this.rowCheckedCount = this.isHeaderChecked ? this.totalCountOfItems : 0;
      this.invalidatedTransactions.forEach(transaction => {
        this.rowIsChecked[transaction.id] = this.isHeaderChecked;
      });
    },
    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },
    dynamicBorderEmailWarning(id) {
      if (!this.validateEmail(this.transactionsDetails[id].email_recipient)) {
        return true;
      }
      return false;
    },
    validFields(invalidFields, field) {
      if (!invalidFields) return true; //check for null value
      const invalidFieldsLength = invalidFields.length;
      for (let i = 0; i < invalidFieldsLength; i++) {
        if (invalidFields[i].key === field) {
          return false;
        }
      }
      return true;
    },
    async initialiseMaps() {
      //re-initialise objects for re-using this function at the time of delete and validate
      this.rowIsChecked = {};
      this.transactionsDetails = {};
      this.totalCountOfItems = 0;
      this.invalidatedTransactions.forEach(transaction => {
        this.transactionsDetails[transaction.id] = transaction;
        this.rowIsChecked[transaction.id] = false;
        this.totalCountOfItems++;
      });
      this.rowCheckedCount = 0;
    },

    isAmountFeildValid(id) {
      const length = this.transactionsDetails[id].invalid_fields.length;
      for (let i = 0; i < length; i++) {
        if (this.transactionsDetails[id].invalid_fields[i].key === AMOUNT) {
          return false;
        }
      }
      return true;
    },
    isBankCodeAndAccountValid(id) {
      const length = this.transactionsDetails[id].invalid_fields.length;
      for (let i = 0; i < length; i++) {
        if (
          this.transactionsDetails[id].invalid_fields[i].key === BANK_CODE ||
          this.transactionsDetails[id].invalid_fields[i].key === ACCOUNT_NUMBER
        ) {
          return false;
        }
      }
      return true;
    },

    async deleteDisbursementItems() {
      try {
        const checkedItems = [];
        for (let id in this.rowIsChecked) {
          this.rowIsChecked[id] === true ? checkedItems.push(id) : null;
        }
        var payload = { disbursement_batch_item_id: checkedItems };
        await deleteDisbursementItems(JSON.stringify(payload));
        await this.getCounts();
        this.toggleDeleteModal();
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.SUCCESS_DELETE")
        );
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENT.ERRORS.FAIL_DELETE_ITEM") + error.message
        );
      }
    },
    updateSelectItem(transactionID, feild, element) {
      const value = element.target.value;
      this.updateItem(transactionID, feild, value);
    },
    updateItem(transactionID, feild, newValue) {
      this.showUpdate = !this.showUpdate;
      if (
        this.transactionsDetails[transactionID] &&
        this.transactionsDetails[transactionID][feild]
      ) {
        this.transactionsDetails[transactionID][feild] = newValue;
      }
      this.showUpdate = !this.showUpdate;
    },
    async getDisbursementItems() {
      try {
        const params = { status: VALIDATION_STATUS };
        const response = await getDisbursementItemsByID(
          this.$route.params.id,
          params
        );
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data.disbursement_batch_items;
          this.invalidatedTransactions = data;
        } else {
          this.invalidatedTransactions = [];
        }
        this.initialiseMaps();
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_DISBURSEMENTS") +
            error.message
        );
      }
    },
    async validateItems() {
      try {
        const payload = [];
        for (let key in this.rowIsChecked) {
          if (this.rowIsChecked[key] === true) {
            payload.push(this.transactionsDetails[key]);
          }
        }
        const response = await validateDisbursementItems(payload);
        await this.getCounts();
        if (!this.verifyHttpResponse(response)) {
          this.showSuccessToaster(
            this.$t("DISBURSEMENTS.SUCCESS.REQUEST_UNDER_REVIEW")
          );
        } else {
          this.showToaster(
            this.$t(
              "DISBURSEMENTS.VALIDATION_ERRORS.ERROR_VALIDATING_TRANSACTIONS"
            )
          );
        }
      } catch (error) {
        this.showToaster(
          this.$t(
            "DISBURSEMENTS.VALIDATION_ERRORS.ERROR_VALIDATING_TRANSACTIONS"
          ) + error.message
        );
      }
    },
    async getBankDetails() {
      try {
        const response = await getBankList();
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.bankDetails = data;
        } else {
          this.showToaster(
            this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BANK_DETAILS")
          );
        }
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BANK_DETAILS") +
            error.message
        );
      }
    },
    getBankDetailsOptions() {
      const options = [];
      this.bankDetails.forEach(bank =>
        options.push({ value: bank.code, label: bank.name })
      );
      return options;
    },
    getAccountOwnerName(id) {
      return this.transactionsDetails[id].account_owner_name;
    }
  },
  watch: {
    totalItems: function() {
      this.getDisbursementItems();
    }
  },
  mounted() {
    this.getDisbursementItems();
    this.getBankDetails();
  }
};
</script> 
<style scoped>
.error-value {
  border-color: #e55353;
}
.table-header td {
  padding-top: 14px;
  padding-bottom: 5px;
}
.error-border > select {
  border-color: red;
}
.warning-border {
  border-color: #ffce00;
}
.inside-input {
  position: relative;
  left: 82%;
  bottom: 30px;
  padding: 2px;
}
.name-column {
  width: 14%;
}
.name-input {
  width: 100%;
}
.input-warning-custom {
  background: #ffffff;
  border: 1px solid #d8dbe0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.18rem;
  font-size: 0.875rem;
  padding-left: 1%;
  width: 100%;
  color: #768192;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}
.input-warning-custom:focus {
  outline-width: 0;
}
</style>