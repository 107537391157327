<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <h4 class="delete-header-message">
        {{ $t("DISBURSEMENTS.DELETE_MODAL.HEADER") }}
      </h4>
      <br />
      <div id="message">{{ $t("DISBURSEMENTS.DELETE_MODAL.MESSAGE") }}</div>
      <br />
      <div class="d-flex flex-row justify-content-between">
        <CButton @click="toggleModal()">
          <strong> {{ $t("DISBURSEMENTS.DELETE_MODAL.CANCEL_BUTTON") }}</strong>
        </CButton>
        <br />
        <CButton color="durianprimary" @click="deleteDisbursementItems()">
          {{ $t("DISBURSEMENTS.DELETE_MODAL.DELETE_BUTTON") }}
        </CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  name: "DeleteDisbursementModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    deleteDisbursementItems: Function,
  },
};
</script>
<style scoped>
.btn-cancel {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
}
.delete-header-message {
  margin-top: 10%;
  margin-left: 25%;
}
</style>