var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('balance-card',{attrs:{"hasButtonTopup":true}}),_c('DisbursementInfo',{attrs:{"batchName":_vm.batchName,"approver":parseInt(_vm.approver, 10),"description":_vm.description},on:{"changeName":function($event){return _vm.updateName($event)},"changeDescription":function($event){return _vm.updateDescription($event)},"changeApprover":function($event){return _vm.updateApprover($event)}}}),_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',[_c('h5',[_vm._v(_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.HEADER")))]),_c('hr'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProgressBarVisible()),expression:"isProgressBarVisible()"}]},[_c('progress-bar',{attrs:{"getCounts":_vm.getCounts,"totalActualCount":_vm.totalActualCount,"totalReviewed":_vm.totalValidatedDisbursementCount +
                    _vm.totalInvalidatedDisbursementCount}})],1),_c('CCard',[_c('CCardBody',{staticClass:"float-left pointer-on-hover",on:{"click":function($event){return _vm.toggleCollapse('validated')}}},[_c('strong',[_c('span',[_c('img',{attrs:{"src":require("../../assets/img/success.png"),"width":"20"}}),_vm._v(" "+_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.VALIDATED"))+" ")])]),_c('span',{staticClass:"text-muted mx-3"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.NUMBER"))+": "),_c('strong',[_vm._v(_vm._s(_vm.totalValidatedDisbursementCount))])]),_c('span',{staticClass:"text-muted mx-3"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT"))+": "),_c('strong',[_vm._v("Rp "+_vm._s(_vm.formatAmount(_vm.totalValidatedDisbursementAmount)))])]),_c('span',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        !_vm.isValidatedCollapsed &&
                        _vm.totalValidatedDisbursementCount !== 0
                      ),expression:"\n                        !isValidatedCollapsed &&\n                        totalValidatedDisbursementCount !== 0\n                      "}],staticClass:"mr-2 float-right",attrs:{"name":"cil-chevron-bottom"}},[_c('down-arrow')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.isValidatedCollapsed &&
                        _vm.totalValidatedDisbursementCount !== 0
                      ),expression:"\n                        isValidatedCollapsed &&\n                        totalValidatedDisbursementCount !== 0\n                      "}],staticClass:"mr-2 float-right"},[_c('up-arrow')],1)])]),_c('CCollapse',{attrs:{"show":_vm.isValidatedCollapsed &&
                    _vm.totalValidatedDisbursementCount !== 0}},[_c('ValidatedDisbursementDetailsTable',{attrs:{"getCounts":_vm.getCounts,"totalItems":_vm.totalValidatedDisbursementCount,"dynamicBorder":_vm.dynamicBorder}})],1)],1),_c('CCard',[_c('CCardBody',{staticClass:"float-left pointer-on-hover",on:{"click":function($event){return _vm.toggleCollapse('with_issues')}}},[_c('strong',[_c('img',{attrs:{"src":require("../../assets/img/warning.png"),"width":"18"}}),_c('span',[_vm._v(_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.WITH_ISSUES")))])]),_c('span',{staticClass:"text-muted mx-3"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.NUMBER"))+": "),_c('strong',[_vm._v(_vm._s(_vm.totalInvalidatedDisbursementCount))])]),_c('span',{staticClass:"text-muted mx-3"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("DISBURSEMENTS.DETAILS.TOTAL_AMOUNT"))+" : "),_c('strong',[_vm._v(" Rp "+_vm._s(_vm.formatAmount(_vm.totalInvalidatedDisbursementAmount))+" ")])]),_c('span',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        !_vm.isInvalidatedCollapsed &&
                        _vm.totalInvalidatedDisbursementCount !== 0
                      ),expression:"\n                        !isInvalidatedCollapsed &&\n                        totalInvalidatedDisbursementCount !== 0\n                      "}],staticClass:"mr-2 float-right"},[_c('down-arrow')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.isInvalidatedCollapsed &&
                        _vm.totalInvalidatedDisbursementCount !== 0
                      ),expression:"\n                        isInvalidatedCollapsed &&\n                        totalInvalidatedDisbursementCount !== 0\n                      "}],staticClass:"mr-2 float-right"},[_c('up-arrow')],1)])]),_c('CCollapse',{attrs:{"show":_vm.isInvalidatedCollapsed &&
                    _vm.totalInvalidatedDisbursementCount !== 0}},[_c('InvalidatedDisbursementDetailsTable',{attrs:{"getCounts":_vm.getCounts,"totalItems":_vm.totalInvalidatedDisbursementCount,"dynamicBorder":_vm.dynamicBorder}})],1)],1)],1)],1)],1)],1)],1)],1),_c('CRow',{staticClass:"mb-5"},[_c('CCol',{staticClass:"col-12"},[_c('CButton',{staticClass:"float-right ml-3 px-5",attrs:{"disabled":_vm.isProgressBarVisible() 
          || _vm.allInvalid() 
          || !_vm.hasPermissionAccess(_vm.permissions.disbursementCreate)
          || _vm.$store.getters.getIsSandboxActive,"color":"durianprimary","size":"lg"},on:{"click":_vm.toggleValidAndInvalidModalsConditionally}},[_vm._v(_vm._s(_vm.$t("DISBURSEMENTS.CREATE_BATCH.SUBMIT_BATCH")))])],1)],1),_c('cancel-submission',{attrs:{"isModalOpen":_vm.isCancelModalOpen,"toggleModal":_vm.toggleCancelInvalidatedModal}}),_c('submit-invalid-disbursement-confirmation',{attrs:{"isModalOpen":_vm.isSubmitInvalidatedConfirmationModalOpen,"toggleModal":_vm.toggleSubmitInvalidatedModal,"submitBatch":_vm.submitBatch,"totalValidatedDisbursementCount":_vm.totalValidatedDisbursementCount,"totalInvalidatedDisbursementCount":_vm.totalInvalidatedDisbursementCount}}),_c('submit-disbursement-confirmation-modal',{attrs:{"isModalOpen":_vm.isSubmitValidatedConfirmationModalOpen,"toggleModal":_vm.toggleSubmitValidatedModal,"submitBatch":_vm.submitBatch,"totalValidatedDisbursementCount":_vm.totalValidatedDisbursementCount,"totalValidatedDisbursementAmount":_vm.totalValidatedDisbursementAmount}}),_c('submission-modal',{attrs:{"name":_vm.batchName,"description":_vm.description,"disbursementID":_vm.disbursementID,"totalTransactions":_vm.totalActualCount - _vm.totalInvalidatedDisbursementCount,"totalAmount":_vm.totalAmount,"isModalOpen":_vm.isSubmissionModalOpen,"invalidCount":_vm.totalInvalidatedDisbursementCount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }