<template>
  <CCard>
    <CCardHeader>
      <strong>{{ $t("DISBURSEMENTS.CREATE_BATCH.INFO_TITLE") }}</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
            class="col-12 px-0"
            :label="getBatchNameLabel()"
            @input="(value) => updateValue(value, 'name')"
            :value="updatedName"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            class="col-12 px-0"
            :label="getBatchDescriptionLabel()"
            @input="(value) => updateValue(value, 'description')"
            :value="updatedDescription"
          />
        </CCol>
        <CSelect
          id="approver"
          :label="getApproverLabel()"
          :options="getOptions()"
          @input="(event) => updateSelectItem(event)"
          class="col-12"
          :value="updatedApprover"
        />
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
const NAME = "name";
const APPROVER = "approver";
const DESCRIPTION = "description";
const CHANGE_NAME = "changeName";
const CHANGE_DESCRIPTION = "changeDescription";
const CHANGE_APPROVER = "changeApprover";

import {
  getDisbursementApprovers,
  getDisbursementAdmins,
} from "@/api/disbursement.api.js";
export default {
  name: "DisbursementBatchInfo",
  data() {
    return {
      approvers: [],
      updatedApprover: "",
      updatedName: "",
      updatedDescription: "",
    };
  },
  props: {
    batchName: String,
    approver: Number,
    description: String,
  },
  methods: {
    getBatchNameLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.BATCH_NAME");
    },
    getBatchDescriptionLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.BATCH_DESCRIPTION");
    },
    getApproverLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.APPROVER");
    },
    getOptions() {
      const options = [];
      for (let key in this.approvers) {
        options.push({
          value: this.approvers[key].id,
          label: this.approvers[key].name,
        });
      }
      return options;
    },
    updateSelectItem(event) {
      const value = event.target.value;
      this.updateValue(value, APPROVER);
    },
    updateValue(value, type) {
      if (type === NAME) {
        this.updatedName = value;
        this.$emit(CHANGE_NAME, this.updatedName);
      } else if (type === DESCRIPTION) {
        this.updatedDescription = value;
        this.$emit(CHANGE_DESCRIPTION, this.updatedDescription);
      } else if (type === APPROVER) {
        this.updatedApprover = value;
        this.$emit(CHANGE_APPROVER, parseInt(this.updatedApprover, 10));
      }
    },
    async getApproversList() {
      try {
        const response = await getDisbursementApprovers();
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.approvers = data;
        } else {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST"));
        }
        const adminResponse = await getDisbursementAdmins();
        if (!this.verifyHttpResponse(adminResponse)) {
          const adminData = adminResponse.data.data;
          this.approvers = this.approvers.concat(adminData);
        } else {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST"));
        }
      } catch (error) {
        this.showToaster(
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST")),
          error.message
        );
      }
    },
  },
  watch: {
    batchName: function () {
      this.updatedName = this.batchName;
    },
    description: function () {
      this.updatedDescription = this.description;
    },
    approver: function () {
      this.updatedApprover = this.approver;
    },
  },
  mounted() {
    this.getApproversList();
  },
};
</script>